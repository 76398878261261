import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radio-button",
      "style": {
        "position": "relative"
      }
    }}>{`Radio Button`}</h1>
    <p>{`São usados em listas com poucas opções e mutuamente exclusivas, permitindo que o usuário selecione apenas uma opção.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`As opções apresentadas devem estar sempre agrupadas e acompanhadas de uma label.`}</li>
      <li parentName="ul">{`Sempre deve vir selecionada uma opção por padrão. `}<strong parentName="li">{`Exceto`}</strong>{` quando uma opção padrão pode causar danos ao usuário ou implica em uma ação crítica/importante do sistema. Nesses casos pode-se apresentar a lista sem nenhuma opção padrão e adicionar uma opção de limpar a seleção.`}</li>
      <li parentName="ul">{`Ordene a lista em ordem lógica, por exemplo: opção mais provável de ser selecionada primeiro/menos provável por último; opção mais simples para a mais complexa, etc.`}</li>
      <li parentName="ul">{`Para listas com mais de 4 opções considere utilizar o componente `}<a parentName="li" {...{
          "href": "/components/select"
        }}>{`Select`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h2>
    <Demo src='pages/components/radio-button/RadioExample' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      